
/**
 * Created by wangtong on 2022/6/12
 */
import {Component, Vue, Prop, Watch} from "vue-property-decorator";
import {PropType} from 'vue'
import {ImageStyle} from "@/constants";
import * as config from "@/config"
import {buildOssUrl} from "@/utils";

@Component({})
export default class OssImageView extends Vue {
    @Prop() imgPath!: string
    @Prop() imgStyle?: ImageStyle

    get url(): string {
        return buildOssUrl(this.imgPath, this.imgStyle)
    }
}
