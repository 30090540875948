
/**
 * Created by wangtong on 2022/6/12
 */
import {Component, Vue, Prop, Watch} from "vue-property-decorator";
import {PropType} from 'vue'
import {createUserShareData, UserShareData} from "@/models";
import {UserApi} from "@/api/user";
import UserDivisionAvatarView from "@/components/user/UserDivisionAvatarView.vue";
import * as R from "@/config/R"
import { UserDivisionLevelCons } from "@/constants";
const icSplash = require("@/assets/images/ic_splash.png")

@Component({
    components: {UserDivisionAvatarView}
})
export default class UserShareCard extends Vue {
    @Prop() shareId!: any
    @Prop() shareData!: UserShareData

    get shareLink(): string {
        return `https://sandanapp.com/share/user/${this.shareId}`
    }

    icSplash = icSplash

    get divisionLogo(): string {
        return R.userDivisionLogoImage(this.shareData.user.info.level)
    }

    get containerStyle(): any {
        return {
            'background-image': R.userDivisionBackgroundGradient(this.shareData.user.info.level)
        }
    }

}
