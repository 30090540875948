
/**
 * Created by wangtong on 2022/6/12
 */
import {Component, Vue, Prop, Watch} from "vue-property-decorator";
import {PropType} from 'vue'
import {UserData} from "@/models";
import OssImageView from "@/components/basic/OssImageView.vue";
import {ImageStyle, UserDivisionLevelCons} from "@/constants";
import * as R from "@/config/R"

@Component({
    components: {OssImageView}
})
export default class UserDivisionAvatarView extends Vue {
    @Prop() size!: number
    @Prop() user!: UserData
    @Prop() imgStyle?: ImageStyle

    get avatarStyle(): any {
        return {
            'position': 'absolute',
            'width': `${this.size}px`,
            'height': `${this.size}px`,
            'left': '0',
            'right': '0',
            'top': '0',
            'bottom': '0',
            'overflow': 'hidden',
        }
    }

    get frameStyle(): any {
        return {
            'position': 'absolute',
            'width': `${this.size * 1.6}px`,
            'height': `${this.size * 1.6}px`,
            'left': `-${this.size * 0.3}px`,
            'top': `-${this.size * 0.3}px`,
        }
    }

    get parentStyle(): any {
        return {
            'position': 'relative',
            'width': `${this.size}px`,
            'height': `${this.size}px`
        }
    }

    get frameImage(): string {
        return R.userDivisionFrameImage(this.user.info.level)
    }
}
